import $ from "jquery";

document.addEventListener(
    `DOMContentLoaded`,
    () => {
        // Change dropdown mobile menu position based on sticky state.
        const stickyElement = document.querySelector("[data-sticky]");
        if (stickyElement) {
            const stickyHeightObserver = new ResizeObserver((_) => {
                if (stickyElement.classList.contains('is-stuck')) {
                    const stickyHeight = $("[data-sticky]").height();
                    $(".ms_dropdown-mobile-menu").attr("style", `position: fixed; top: ${stickyHeight}px`);
                } else {
                    $(".ms_dropdown-mobile-menu").attr("style", "position: absolute; top: unset");
                }
            });
            stickyHeightObserver.observe(stickyElement);
        }

        // Properly update the ARIA states on focus (keyboard) and mouse over events.
        $('[role="menubar"]').on("focus.aria  mouseenter.aria", '[aria-haspopup="true"]', (event) => {
            $(event.currentTarget).attr("aria-expanded", true);
        });

        // Properly update the ARIA states on blur (keyboard) and mouse out events.
        $('[role="menubar"]').on("blur.aria  mouseleave.aria", '[aria-haspopup="true"]', (event) => {
            $(event.currentTarget).attr("aria-expanded", false);
        });

        document.addEventListener(
            "toggle-mobile-menu",
            function (event) {
                const open = event.detail?.open ?? false;
                const type = event.detail?.type ?? "dropdown";

                if (type === "overlay") {
                    const logo = document.querySelector(".logo-image-overlay");
                    const logoMobile = document.querySelector(".logo-mobile-image-overlay");
                    const language = document.querySelector(".language-block");
                    const overlay = document.querySelector(".top-bar-overlay");
                    const overlayItems = overlay.querySelectorAll("li");
                    if (!open) {
                        document.body.classList.remove("overflow-hidden");
                        if (logo) {
                            logo.classList.remove("animate__fadeInUp");
                        }
                        if (logoMobile) {
                            logoMobile.classList.remove("animate__fadeInUp");
                        }
                        if (language) {
                            language.classList.remove("animate__fadeInUp");
                        }
                        for (const li of overlayItems) {
                            li.classList.remove("animate__fadeInUp");
                        }
                    } else {
                        document.body.classList.add("overflow-hidden");
                        if (logo) {
                            logo.classList.add("animate__fadeInUp");
                        }
                        if (logoMobile) {
                            logoMobile.classList.add("animate__fadeInUp");
                        }
                        if (language) {
                            language.classList.add("animate__fadeInUp");
                        }
                        for (const li of overlayItems) {
                            li.classList.add("animate__fadeInUp");
                        }
                    }
                }
            },
            { passive: true },
        );
    },
    { passive: true },
);
