document.addEventListener("DOMContentLoaded", function () {
    const timelines = document.querySelectorAll(".ms_timeline .timeline");
    if (timelines.length) {
        timelines.forEach((timeline) => {
            if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
                timeline.removeAttribute("data-animation");
            }

            const shouldAnimate = !(
                !timeline.getAttribute("data-animation") || "on" != timeline.getAttribute("data-animation")
            );
            if (!shouldAnimate) {
                return;
            }

            const sections = timeline.querySelectorAll("section");
            const observer = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate");
                            observer.unobserve(entry.target);
                        }
                    });
                },
                { rootMargin: "0px 0px -150px 0px" },
            );
            sections.forEach((section) => {
                observer.observe(section);
            });
        });
    }
});
