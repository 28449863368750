import { CountUp } from "countup.js";

const event = new Event("wpsoup.counter.completed", {
    bubbles: true,
    cancelable: true,
    composed: false,
});

function registerEventListeners() {
    const elements = document.querySelectorAll(".counter");
    const intersectionSupported = intersectionListenerSupported();

    if (intersectionSupported) {
        const intersectionObserver = new IntersectionObserver(animateModern, {
            root: null,
            rootMargin: "20px",
            threshold: 0.5,
        });

        for (let i = 0; i < elements.length; i++) {
            intersectionObserver.observe(elements[i]);
        }
    } else {
        if (window.addEventListener) {
            animateLegacy(elements);

            window.addEventListener(
                "scroll",
                (e) => {
                    animateLegacy(elements);
                },
                { passive: true }
            );
        }
    }
}

function animateLegacy(elements) {
    elements.forEach((element) => {
        if (elementIsInView(element)) {
            animateElement(element);
        }
    });
}

function animateModern(elements) {
    elements.forEach((element) => {
        if (element.isIntersecting) {
            animateElement(element);
        }
    });
}

function animateElement(element) {
    const target = element.target ? element.target : element;
    const dataset = target.dataset;

    const countUp = new CountUp(target, dataset.counterEnd, {
        duration: dataset.counterDuration,
        useGrouping: dataset.counterGrouping ? true : false,
        separator: dataset.counterSeparator,
        decimal: dataset.counterDecimal,
    });
    target.counter = countUp;
    countUp.start(() => {
        // Create event listener.
        target.dispatchEvent(event);
    });
}

function elementIsInView(element) {
    let top = element.offsetTop;
    let left = element.offsetLeft;
    const width = element.offsetWidth;
    const height = element.offsetHeight;

    while (element.offsetParent) {
        element = element.offsetParent;
        top += element.offsetTop;
        left += element.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
    );
}

function intersectionListenerSupported() {
    return (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
    );
}

(() => {
    registerEventListeners();
})();
