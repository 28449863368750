/**
 * Set scrollbar sizes
 */
document.addEventListener(
    "DOMContentLoaded",
    () => {
        function setScrollbarSize() {
            // Add temporary box to wrapper.
            let scrollbox = document.createElement("div");
            // Make box scrollable.
            scrollbox.style.overflow = "scroll";
            // Append box to document.
            document.body.appendChild(scrollbox);
            // Measure inner width of box.
            let scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;
            // Remove box.
            document.body.removeChild(scrollbox);

            if (!(document.body.scrollHeight > window.innerHeight)) {
                scrollBarWidth = 0;
            }

            if (scrollBarWidth !== 0) {
                document.body.classList.add("has-scrollbar");
                document.body.setAttribute("style", `--scroll-bar: ${scrollBarWidth}px`);
            } else {
                document.body.classList.remove("has-scrollbar");
            }
        }

        setScrollbarSize();
        window.addEventListener("resize", setScrollbarSize, { passive: true });
    },
    { passive: true }
);
