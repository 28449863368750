document.addEventListener(`DOMContentLoaded`, () => {
    const anchorMenu = document.querySelector(".wp-block-ms-anchor-menu");
    if (anchorMenu) {
        const anchorMenuItems = [...anchorMenu.querySelectorAll("li")].map((item) => {
            const anchor = item.querySelector("a");

            return document.getElementById(anchor.getAttribute("href").slice(1));
        });

        const anchorObserver = new IntersectionObserver(
            function (entries) {
                var anchorMenuLinks = document.querySelectorAll("[data-anchor]");
                entries.map((entry) => {
                    let top = didThisIntersectionHappenAtTop(entry);
                    // Page just loaded ... probably and a heading is in view
                    if (entry.time < 3000 && entry.isIntersecting) {
                        document
                            .querySelectorAll(`[data-anchor="${entry.target.id}"]`)
                            .forEach((el) => el.closest("li").classList.add("active"));
                    } else if (entry.time < 3000) {
                        // In this case page just loaded and no heading in view
                        return;
                    } else if (!top && entry.isIntersecting === false) {
                        // This section deals with scrolling up the page. First we find if the heading being scrolled off the bottom is the first H tag in source order.
                        let indexOfThisHeading = getHeadingIndex(entry);
                        if (indexOfThisHeading === 0) {
                            // The first H tag just scrolled off the bottom of the viewport and it is the first H tag in source order
                            anchorMenuLinks.forEach((link) => link.closest("li").classList.remove("active"));
                        } else {
                            // An H tag scrolled off the bottom. It isn't the first so make the previous heading current
                            anchorMenuLinks.forEach((link) => link.closest("li").classList.remove("active"));
                            document
                                .querySelectorAll(`[data-anchor="${anchorMenuItems[indexOfThisHeading - 1].id}"]`)
                                .forEach((el) => el.closest("li").classList.add("active"));
                        }
                    } else if (entry.isIntersecting) {
                        // For all other instances we want to make this one current and the others not current
                        anchorMenuLinks.forEach((link) => link.closest("li").classList.remove("active"));
                        document
                            .querySelectorAll(`[data-anchor="${entry.target.id}"]`)
                            .forEach((el) => el.closest("li").classList.add("active"));
                    }
                });
            },
            {
                root: null,
                rootMargin: "0px 0px -50% 0px",
                threshold: [1],
            },
        );
        anchorMenuItems.forEach((item) => {
            anchorObserver.observe(item);
        });

        function didThisIntersectionHappenAtTop(entry) {
            return entry.rootBounds.bottom - entry.boundingClientRect.bottom > entry.rootBounds.bottom / 2
                ? true
                : false;
        }

        function getHeadingIndex(entry) {
            let priorEl = (el) => el === entry.target;

            return anchorMenuItems.findIndex(priorEl);
        }
    }
});
