import $ from "jquery";

window.addEventListener(
    "load",
    () => {
        if ($(".scrollhide-nav").length > 0 && Foundation.MediaQuery.only("base")) {
            let prev = 0;
            const height = $(".ms_thintopbar").outerHeight();
            const scrollTop = $(window).scrollTop();
            $(".scrollhide-nav").toggleClass("hide", scrollTop > prev);

            $(window).on("scroll", () => {
                const scrollTop = $(window).scrollTop();
                $(".scrollhide-nav").toggleClass("hide", scrollTop > prev);

                let offset = 0;
                if (scrollTop > prev) {
                    offset = 0;
                } else {
                    offset = height - 1;
                }

                $(".ms-menu .sticky").attr("style", `top: ${offset}px !important`);

                prev = scrollTop;
            });

            $(".ms-menu .sticky").on("sticky.zf.unstuckfrom:top", function () {
                $(".ms-menu .sticky").attr("style", `margin-top: ${height - 1}px !important`);
            });
            $(".ms-menu .sticky").on("sticky.zf.stuckto:top", function () {
                $(".ms-menu .sticky").attr("style", `margin-top: 0px !important`);
            });
        }
    },
    { passive: true }
);
