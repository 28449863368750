function iOS() {
    return (
        (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
        !window.MSStream
    );
}

document.addEventListener(
    "DOMContentLoaded",
    () => {
        if (iOS()) {
            const containers = document.querySelectorAll(".wp-block-ms-container");
            if (containers.length) {
                [...containers].forEach((container) =>
                    container.style.setProperty("background-attachment", "scroll", "important")
                );
            }
        }
    },
    { passive: true }
);
