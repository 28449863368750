// Masonry layout.
document.addEventListener(
    "DOMContentLoaded",
    () => {
        if (typeof masonryGrids !== "undefined") {
            // Loop through each registered masonry grids and initialize them.
            masonryGrids = masonryGrids.map(({ container, id }) => {
                masonryGridPolyfill(container, true);

                // Return instance for usage with AJAX content.
                return {
                    id,
                    container,
                };
            });
        }
    },
    { passive: true },
);

let observers = [];

function masonryGridPolyfill(selectors = ".masonry-grid", firstChild = false, clearObservers = false) {
    const elements = [...document.querySelectorAll(selectors)];

    // Bail if no elements where found.
    if (!elements.length) return;

    // Bail if masonry layouts are already supported by the browser.
    if (getComputedStyle(elements[0]).gridTemplateRows === "masonry") return;

    if (clearObservers) {
        observers.forEach((observer) => observer.disconnect());
        observers = [];
    }

    for (const el of elements) {
        const grid = {
            el,
            items: [...el.childNodes].filter((i) => i.nodeType === 1),
            gap: parseFloat(getComputedStyle(el).rowGap),
            columns: 0,
            count: 0,
        };

        renderGrid(grid, firstChild);

        const resizeObserver = new ResizeObserver(() => renderGrid(grid, firstChild));
        resizeObserver.observe(grid.el);
        observers.push(resizeObserver);
    }
}

function renderGrid(grid, firstChild) {
    const columns = getComputedStyle(grid.el).gridTemplateColumns.split(" ").length;

    for (const column of grid.items) {
        const currentColumn = firstChild ? column.firstElementChild : column;
        const { height } = currentColumn.getBoundingClientRect();
        const h = height.toString();

        if (h !== column.dataset?.h) {
            column.dataset.h = h;
            grid.count++;
        }
    }

    // Bail if the number of columns hasn't changed.
    if (grid.columns === columns && grid.count) return;

    // Update the number of columns.
    grid.columns = columns;

    // Revert to initial positioning, no margin.
    for (const { style } of grid.items) style.removeProperty("margin-top");

    // If we have more than one column.
    if (grid.columns > 1) {
        for (const [index, column] of grid.items.slice(columns).entries()) {
            // Bottom edge of item above.
            const aboveColumn = firstChild ? grid.items[index].firstElementChild : grid.items[index];
            const { bottom: prevBottom } = aboveColumn.getBoundingClientRect();
            // Top edge of current item.
            const currentColumn = firstChild ? column.firstElementChild : column;
            const { top } = currentColumn.getBoundingClientRect();

            column.style.marginTop = `${prevBottom + grid.gap - top}px`;
        }
    }

    grid.count = 0;
}

window.masonryGridPolyfill = masonryGridPolyfill;
