/**
 * Dynamically intialize the `.review-slider`'s.
 */
function msInitializeReviewSlider() {
    const slider = document.querySelector(`.review-slider`);
    if (slider) {
        let options = slider.dataset.sliderOptions;
        options = JSON.parse(options);
        const reviewSlider = new Swiper(`.review-slider`, options);
    }
}

document.addEventListener(
    `DOMContentLoaded`,
    function () {
        msInitializeReviewSlider();
    },
    { passive: true }
);

if (typeof acf !== "undefined") {
    acf.addAction(`render_block_preview`, function ($el, attributes) {
        msInitializeReviewSlider();
    });
}
