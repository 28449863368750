import ky from "ky";

document.addEventListener(
    `DOMContentLoaded`,
    () => {
        const favoriteButtons = document.querySelectorAll(".favorite-button");
        for (const button of favoriteButtons) {
            button.addEventListener(
                "click",
                function (event) {
                    const postID = this.dataset.postId;
                    if (!postID) {
                        return;
                    }

                    const action = this.dataset.action;
                    if (action === "add") {
                        addToFavorites(postID, button);
                    } else if (action === "remove") {
                        removeFromFavorites(postID, button);
                    }
                },
                { passive: true }
            );
        }

        async function addToFavorites(postID, button) {
            let formData = new FormData();
            formData.append("action", "add_to_favorites");
            formData.append("security", frameworkGlobal.nonce);
            formData.append("post_id", postID);

            try {
                const response = await ky.post(frameworkGlobal.ajaxurl, { body: formData }).json();
                if (response.success) {
                    button.classList.add("is-favorited");
                    button.querySelector("iconify-icon").outerHTML = frameworkGlobal.wpsoup.favorites.button_icon_favorited;
                    button.dataset.action = "remove";
                }
            } catch (error) {
                //
            }
        }

        async function removeFromFavorites(postID, button) {
            let formData = new FormData();
            formData.append("action", "remove_from_favorites");
            formData.append("security", frameworkGlobal.nonce);
            formData.append("post_id", postID);

            try {
                const response = await ky.post(frameworkGlobal.ajaxurl, { body: formData }).json();
                if (response.success) {
                    button.classList.remove("is-favorited");
                    button.querySelector("iconify-icon").outerHTML = frameworkGlobal.wpsoup.favorites.button_icon;
                    button.dataset.action = "add";
                }
            } catch (error) {
                //
            }
        }
    },
    { passive: true }
);
