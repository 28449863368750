import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";

import.meta.glob("@theme/assets/**/script-alpine.js", { eager: true });
import.meta.glob("../../src/Core/Blocks/{Content,Marginals}/**/**/alpine.js", { eager: true });
import.meta.glob("@theme/resources/blocks/**/**/alpine.js", { eager: true });
import.meta.glob("@theme/resources/marginals/**/**/alpine.js", { eager: true });

Alpine.plugin(collapse);
Alpine.plugin(focus);

window.Alpine = Alpine;

Alpine.start();
