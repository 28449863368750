import $ from "jquery";
import Swiper from "swiper";
import { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, EffectCoverflow, EffectFade } from "swiper/modules";
Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Thumbs, EffectCoverflow, EffectFade]);
window.Swiper = Swiper;

import "swiper/css/bundle";

const initializeSliders = function (sliders) {
    if (sliders.thumb != null) {
        // Get slider options.
        let mainOptions = sliders.main.dataset.sliderOptions;
        mainOptions = JSON.parse(mainOptions);
        let thumbOptions = sliders.thumb.dataset.sliderOptions;
        thumbOptions = JSON.parse(thumbOptions);

        // Give the prev & next button an unique class,
        // then tell the options that those are the prev & next button.
        const btns = ["prev", "next"];
        btns.forEach((btn) => {
            // Get button element.
            const mainButton = document.querySelector(`#${sliders.id} .swiper-button-${btn}.main-button`);
            if (mainButton) {
                // Generate unique class.
                const btnClass = `${sliders.id}-main-${btn}`;
                // Add class to button.
                mainButton.classList.add(btnClass);
                // Add class to options.
                const key = `${btn}El`;
                mainOptions["navigation"][key] = `.${btnClass}`;
            }

            // Get button element.
            const thumbButton = document.querySelector(`#${sliders.id} .swiper-button-${btn}.thumbnail-button`);
            if (thumbButton) {
                // Generate unique class.
                const btnClass = `${sliders.id}-thumb-${btn}`;
                // Add class to button.
                thumbButton.classList.add(btnClass);
                // Add class to options.
                const key = `${btn}El`;
                thumbOptions["navigation"][key] = `.${btnClass}`;
            }
        });

        // Initialize the slider.
        let thumbSlider = new Swiper(`#${sliders.id} .thumbnail-carousel`, thumbOptions);
        mainOptions.thumbs = {
            swiper: thumbSlider,
        };
        let mainSlider = new Swiper(`#${sliders.id} .carousel`, mainOptions);
        mainSlider.on("slideChange", function (event) {
            const currentSlide = mainSlider.slides[mainSlider.activeIndex];
            // Play the video in the slider when the current slide contains the video.
            if (currentSlide.classList.contains("youtube")) {
                const iframe = currentSlide.querySelector("iframe");
                if (iframe) {
                    // iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                }
            } else {
                const slides = mainSlider.slides;
                slides.forEach(function (slide) {
                    const iframe = slide.querySelector("iframe");
                    if (iframe) {
                        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
                    }
                });
            }
        });
    } else {
        // Get slider options.
        let mainOptions = sliders.main.dataset.sliderOptions;
        mainOptions = JSON.parse(mainOptions);

        // Give the prev & next button an unique class,
        // then tell the options that those are the prev & next button.
        const btns = ["prev", "next"];
        btns.forEach((btn) => {
            // Get button element.
            const mainButton =
                document.querySelector(`#${sliders.id} .swiper-button-${btn}.main-button`) ??
                document.querySelector(`#${sliders.id} .swiper-button-${btn}`);
            if (mainButton) {
                // Generate unique class.
                const btnClass = `${sliders.id}-main-${btn}`;
                // Add class to button.
                mainButton.classList.add(btnClass);
                // Add class to options.
                const key = `${btn}El`;
                mainOptions["navigation"][key] = `.${btnClass}`;
            }
        });

        // Initialize the slider.
        const swiperSelector = sliders?.selector ? `#${sliders.id} .${sliders.selector}` : `#${sliders.id} .carousel`;
        const mainSlider = new Swiper(swiperSelector, mainOptions);

        if (typeof cardSliders !== "undefined") {
            // Loop through each registered card slider and initialize them.
            cardSliders = cardSliders.map(({ container, id }) => {
                if (id === sliders.id) {
                    // Return instance for usage with AJAX content.

                    return {
                        id,
                        container,
                        instance: mainSlider,
                    };
                }

                return {
                    id,
                    container,
                };
            });
        }
    }
};
window.initializeSliders = initializeSliders;

// Apply above function to all sliders.
function msInitializeDynamicSliders() {
    let listOfSliders = [];
    const allContentCarouselSliders = document.querySelectorAll(".ms_content-carousel");
    for (const sliders of allContentCarouselSliders) {
        listOfSliders.push({
            id: sliders.id,
            main: sliders.querySelector(".swiper"),
            thumb: null,
            selector: "swiper",
        });
    }

    const allCarouselSliders = document.querySelectorAll(".ms_carousel");
    for (const sliders of allCarouselSliders) {
        listOfSliders.push({
            id: sliders.id,
            main: sliders.querySelector(".carousel"),
            thumb: sliders.querySelector(".thumbnail-carousel"),
            selector: "carousel",
        });
    }

    const allCardSliders = document.querySelectorAll(".ms_cards");
    for (const sliders of allCardSliders) {
        if (sliders.querySelector(".card-slider")) {
            listOfSliders.push({
                id: sliders.id,
                main: sliders.querySelector(".card-slider"),
                thumb: null,
                selector: "card-slider",
            });
        }
        if (sliders.querySelector(".card-mobile-slider")) {
            listOfSliders.push({
                id: sliders.id,
                main: sliders.querySelector(".card-mobile-slider"),
                thumb: null,
                selector: "card-mobile-slider",
            });
        }
    }

    const allProductSliders = document.querySelectorAll(".ms_products");
    for (const sliders of allProductSliders) {
        if (sliders.querySelector(".product-slider")) {
            listOfSliders.push({
                id: sliders.id,
                main: sliders.querySelector(".product-slider"),
                thumb: null,
                selector: "product-slider",
            });
        }
        if (sliders.querySelector(".product-mobile-slider")) {
            listOfSliders.push({
                id: sliders.id,
                main: sliders.querySelector(".product-mobile-slider"),
                thumb: null,
                selector: "product-mobile-slider",
            });
        }
    }

    const allTestimonialSliders = document.querySelectorAll(".ms_testimonial-quote");
    for (const sliders of allTestimonialSliders) {
        if (sliders.querySelector(".item-slider")) {
            listOfSliders.push({
                id: sliders.id,
                main: sliders.querySelector(".item-slider"),
                thumb: null,
                selector: "item-slider",
            });
        }
    }

    const allUSPSSliders = document.querySelectorAll(".ms_usps");
    for (const sliders of allUSPSSliders) {
        if (sliders.querySelector(".usps-slider")) {
            listOfSliders.push({
                id: sliders.id,
                main: sliders.querySelector(".usps-slider"),
                thumb: null,
                selector: "usps-slider",
            });
        }
    }

    if (listOfSliders.length !== 0) {
        for (const sliders of listOfSliders) {
            initializeSliders(sliders);
        }
    }
}

document.addEventListener(
    `DOMContentLoaded`,
    function () {
        msInitializeDynamicSliders();
    },
    { passive: true },
);

if (typeof acf !== "undefined") {
    acf.addAction(`render_block_preview`, function ($el, attributes) {
        msInitializeDynamicSliders();
    });
}

$('.swiper-button-next, .swiper-button-prev').on('mousedown', function(e) {
    e.preventDefault();
});
